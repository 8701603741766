var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("予約実績(履歴)")]),[_c('v-spacer'),(_vm.apierror.status == 'error')?_vm._l((_vm.apierror.messages),function(msg){return _c('div',{key:msg},[_c('v-row',{staticClass:"ml-6 mb-3 ma-3"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("* "+_vm._s(msg)+" ")])])],1)}):_vm._e(),[_c('v-spacer'),_c('v-form',{staticClass:"ma-6"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserve_id"}},[_vm._v("予約ID")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":"reserve_id"}},[_vm._v(_vm._s(_vm.reservedProgress.resv_id))])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserve_id"}},[_vm._v("商品ID")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"for":"reserve_id","href":"javascript:void(0);"},on:{"click":_vm.openOverviewDialog}},[_vm._v(" "+_vm._s(_vm.reservedProgress.po_code)+" ("+_vm._s(_vm.reservedProgress.po_id)+") ")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"customer_id"}},[_vm._v("取引先")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"text-decoration":"underline","color":"#666666"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.reservedProgress.cust_abbr)+"("+_vm._s(_vm.reservedProgress.cust_id)+")")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.reservedProgress.cust_name))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.reservedProgress.cust_name_eng))]),_c('br')])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_dt"}},[_vm._v("予約日時")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('label',[_vm._v(_vm._s(_vm.reservedProgress.resv_dt))])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_weight"}},[_vm._v("総重量")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_weight"}},[_vm._v(" "+_vm._s(_vm.formatData(_vm.reservedProgress.resv_weight))+" KG ")])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_amount"}},[_vm._v("総金額")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_amount"}},[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.reservedProgress.ccy_mark,_vm.reservedProgress.resv_amount)))])])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"resv_status"}},[_vm._v("現在状態")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"resv_status"}},[_vm._v(_vm._s(_vm.reservedProgress.resv_stat_desc))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.ProgressDetail,"item-key":"no","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.resv_stat_desc",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.resv_stat_desc)+" "+_vm._s(_vm.getProcFrom(item.proc_from))+" ")])]}},{key:"item.proc_memo_cust",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.proc_memo_cust)+" ")])]}},{key:"item.proc_memo_admin",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.proc_memo_admin)+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"reserved_note"}},[_vm._v("備考")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-textarea',{attrs:{"name":"resv_note","outlined":"","dense":"","placeholder":"変更内容など記入","hide-details":""},model:{value:(_vm.resv_note),callback:function ($$v) {_vm.resv_note=$$v},expression:"resv_note"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"offset-md":"5","cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.submitStatus},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":_vm.cancelClick}},[_vm._v(" Cancel ")])],1)],1)],1),_c('ReservedPreview',{attrs:{"id":_vm.reservedProgress.po_id,"resv-dialog":_vm.resvDialog},on:{"closeForm":_vm.closeReservedPreivew}})]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }